$(function () {
  $("body").on("click keypress mouseover", function () {
    ResetThisSession();
  });
});

function ReloadUrl() {
  return "/signin";
}

const timeInSecondsAfterSessionOut = 1800;
let secondTick = 0;

function ResetThisSession() {
  secondTick = 0;
}

function StartThisSessionTimer() {
  secondTick++;
  const timeLeft = timeInSecondsAfterSessionOut + 1 - secondTick;
  if (timeLeft === 1800 || timeLeft % 60 === 0) {
    const tl = convertToBnEn((timeLeft/60).toString());
    $("#spanTimeLeft").html(tl + "মি.");
  }
  if (timeLeft < 60) {
    const tl = convertToBnEn(timeLeft.toString());
    $("#spanTimeLeft").html(tl + "সে.");
  }

  if (secondTick > timeInSecondsAfterSessionOut) {
    clearTimeout(tick);
    window.location = ReloadUrl();
    return;
  }
  tick = setTimeout("StartThisSessionTimer()", 1000);
}
StartThisSessionTimer();

function convertToBnEn(str) {
  if (!str) {
    return "";
  }
  let newStr = "";
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < str.length; i++) {
    switch (str[i]) {
    case "0":
      newStr += "০";
      break;
    case "1":
      newStr += "১";
      break;
    case "2":
      newStr += "২";
      break;
    case "3":
      newStr += "৩";
      break;
    case "4":
      newStr += "৪";
      break;
    case "5":
      newStr += "৫";
      break;
    case "6":
      newStr += "৬";
      break;
    case "7":
      newStr += "৭";
      break;
    case "8":
      newStr += "৮";
      break;
    case "9":
      newStr += "৯";
      break;
    default:
      newStr += str[i];
      break;
    }
  }
  return newStr.toString();
}